import * as React from "react";

// plugin imports

// utils imports

// svg imports

export const RouteContext = React.createContext({
	location: undefined,
});

const defaultValues = {
	showAllergens: false,
	allergensList: {},
};

export const AllergensContext = React.createContext(defaultValues);

export const AllergensProvider = ({ children }) => {
	const [showAllergens, setShowAllergens] = React.useState(false);

	const allergensList = [
		"Gluten",
		"Crustáceos",
		"Huevo",
		"Pescado",
		"Cacahuetes",
		"Soja",
		"Lácteos",
		"Frutos de cáscara",
		"Apio",
		"Mostaza",
		"Granos de sésamo",
		"Dióxido de azufre y sulfitos",
		"Moluscos",
		"Altramuces",
		"Vegano",
		"Vegetariano",
	]; // This array must match arraySelector.js/allergensNames array

	return (
		<AllergensContext.Provider
			value={{
				...defaultValues,
				showAllergens,
				setShowAllergens,
				allergensList,
			}}
		>
			{children}
		</AllergensContext.Provider>
	);
};
